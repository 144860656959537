<template>
  <div>
    <div
      id="condoMap"
      class="google-map"
      ref="googleMap"
      :style="{ height: height }"
    ></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>

    <InfoWindow ref="info-content">
      <router-link
        v-if="condo != null"
        :to="'/condos/' + condo.place_id"
        @click.stop="updateCondo(condo.place_id)"
      >
        <div class="box p-2">
          <article class="media">
            <div class="media-content">
              <div class="content is-size-6-7">
                <p class="info-content cursor-pointer">
                  <strong v-if="condo.info" class="mb-3">{{
                    condo.info.name
                  }}</strong>
                  <strong v-else class="mb-3">{{ condo.name }}</strong>
                  <small>
                    <div class="level is-mobile is-size-7 pt-1">
                      <!-- Left side -->
                      <div class="level-left">
                        <div class="level-item has-text-left">
                          <span>
                            <i class="fa fa-star has-text-primary" />
                            <span class="">
                              {{ condo.rating }} ({{
                                condo.user_ratings_total
                              }})
                            </span>
                            <span>
                              <i class="fa fa-road has-text-info" />
                              <span>
                                {{
                                  parseInt(
                                    condo.station_distances[activeStation]
                                  )
                                }}m
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </small>
                </p>
              </div>
            </div>
          </article>
        </div>
      </router-link>
    </InfoWindow>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InfoWindow from "@/components/maps/InfoWindow";
// import CondoCard from "@/components/condos/CondoCard";
import gmaps from "@/utils/gmaps";

export default {
  name: "Map",
  props: ["height"],
  components: {
    InfoWindow,
    // CondoCard,
  },
  data() {
    return {
      condo: null,
      currentCondoId: "",
      panPath: [], // An array of points the current panning action will use
      panQueue: [], // An array of subsequent panTo actions to take
      STEPS: 100,
      infowindow: null,
      google: null,
      map: null,
      markers: {},
      stationMarker: null,
      mapConfig: {
        center: { lat: 13.756331, lng: 100.501762 },
        zoom: 15,
        minZoom: 15,
        maxZoom: 16,
        // mapId: "6c9c2206b7a3d1dd",
        mapId: "577cfeccac9ebb96",
        backgroundColor: "#f6f6f6",
        strokeColor: "#f6f6f6",
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        zoomControl: false,
        gestureHandling: "greedy",
        restriction: {
          latLngBounds: {
            north: 13.95,
            south: 13.55,
            west: 100.35,
            east: 100.8,
          },
          strictBounds: true,
        },
      },
    };
  },

  async beforeMount() {
    this.google = await gmaps.init();
    this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);

    // for click outside
    this.map.addListener("click", () => {
      this.currentCondoId = "";
    });

    this.currentCondoId = this.activeCondo;
    this.infowindow = new this.google.maps.InfoWindow({ content: "==" });

    if (this.activeStation != "") {
      const station = this.stations[this.activeStation];
      const position = {
        lat: station.lat,
        lng: station.lng,
      };
      this.updateStationMarker();
      this.updateCondoMarkers();
      this.map.setCenter(position);
    }
  },
  mounted() {},
  watch: {
    $route() {
      this.currentCondoId = this.$route.params.id;
    },
    activeStation: function() {
      this.updateStationMarker();
    },
    currentCondoId: function() {
      if (this.currentCondoId == "" || this.currentCondoId == undefined) {
        this.condo = null;
        this.infowindow.close();
      } else {
        this.condo = this.condos[this.currentCondoId];
        this.infowindow.setContent(this.$refs["info-content"].$el);
        this.infowindow.open(this.map, this.markers[this.currentCondoId]);
      }
    },
    condos: function() {
      this.updateCondoMarkers();
    },
  },

  methods: {
    ...mapActions(["updateCondo"]),

    getMapURL: function() {
      let url = "https://www.google.com/maps/preview?q=";
      url += this.condo.name.replaceAll(" ", "+");
      return url;
    },
    updateCondoMarkers: function() {
      for (const [, marker] of Object.entries(this.markers)) {
        marker.setMap(null);
      }

      // console.log("n_condos: ", Object.keys(this.condos).length);

      for (const [condoId, condo] of Object.entries(this.condos)) {
        const marker = new this.google.maps.Marker({
          position: {
            lat: condo.geometry.location.lat,
            lng: condo.geometry.location.lng,
          },
          map: this.map,
          animation: this.google.maps.Animation.DROP,
          icon: {
            url: condo.profile || "/marker-v6.png",
            scaledSize: new this.google.maps.Size(30, 30), // scaled size
            anchor: new this.google.maps.Point(14, 31), // anchor(high-right, high-up)
            opacity: 0.5,
          },
        });

        marker.addListener("click", () => {
          // marker toggle
          if (this.currentCondoId != condoId) {
            this.currentCondoId = condoId;
          } else {
            this.currentCondoId = "";
          }
        });
        if (condoId == this.currentCondoId) {
          this.condo = this.condos[this.currentCondoId];
          this.infowindow.setContent(this.$refs["info-content"].$el);
          this.infowindow.open(this.map, marker);
        }

        this.markers[condoId] = marker;
      }
    },
    updateStationMarker: function() {
      if (this.stationMarker != null) {
        this.stationMarker.setMap(null);
        this.map.setZoom(this.mapConfig.zoom);
      }

      if (this.activeStation != "") {
        const station = this.stations[this.activeStation];
        const position = {
          lat: station.lat,
          lng: station.lng,
        };

        this.stationMarker = new this.google.maps.Marker({
          position: position,
          map: this.map,
          icon: {
            url: "/marker.svg",
            anchor: new this.google.maps.Point(250 / 2, 250 / 2),
          },
          clickable: false,
        });
        this.map.panTo(position);
      }
    },
    panTo: function(newLat, newLng) {
      // https://stackoverflow.com/questions/3817812/google-maps-v3-can-i-ensure-smooth-panning-every-time/33339155
      if (this.panPath.length > 0) {
        // We are already panning...queue this up for next move
        this.panQueue.push([newLat, newLng]);
      } else {
        // Lets compute the points we'll use
        this.panPath.push("LAZY SYNCRONIZED LOCK"); // make length non-zero - 'release' this before calling setTimeout
        var curLat = this.map.getCenter().lat();
        var curLng = this.map.getCenter().lng();
        var dLat = (newLat - curLat) / this.STEPS;
        var dLng = (newLng - curLng) / this.STEPS;

        for (var i = 0; i < this.STEPS; i++) {
          this.panPath.push([curLat + dLat * i, curLng + dLng * i]);
        }
        this.panPath.push([newLat, newLng]);
        this.panPath.shift(); // LAZY SYNCRONIZED LOCK
        setTimeout(this.doPan, 20);
      }
    },
    doPan: function() {
      var next = this.panPath.shift();
      if (next != null) {
        // Continue our current pan action
        this.map.panTo(new this.google.maps.LatLng(next[0], next[1]));
        setTimeout(this.doPan, 20);
      } else {
        // We are finished with this pan - check if there are any queue'd up locations to pan to
        var queued = this.panQueue.shift();
        if (queued != null) {
          this.panTo(queued[0], queued[1]);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["activeStation", "condos", "activeCondo", "stations"]),
    console: () => console,
    window: () => window,
  },
};
</script>

<style scoped>
#condoMap {
  width: 100%;
  height: 40vh;
}

.info-content {
  line-height: 1.7;
}

.gm-style-iw-d {
  cursor: pointer !important;
}
</style>
