<template>
  <div>
    <slot> </slot>
  </div>
</template>
<script>
export default {
  name: "InfoWindow",
};
</script>
<style></style>
