// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis
const API_KEY = "AIzaSyDlGfgVWDaJLh6BZ6GiZms6Upud-WzT27Q";
const MAP_ID = "6c9c2206b7a3d1dd";
const CALLBACK_NAME = "initMap";

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default {
  init() {
    // If Google Maps already is initialized
    // the `initPromise` should get resolved
    // eventually.
    if (initialized) return initPromise;

    initialized = true;
    // The callback function is called by
    // the Google Maps script if it is
    // successfully loaded.
    window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

    // We inject a new script tag into
    // the `<head>` of our HTML to load
    // the Google Maps script.
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}&libraries=&v=weekly&map_ids=${MAP_ID}`;
    script.onerror = rejectInitPromise;
    document.querySelector("head").appendChild(script);

    return initPromise;
  },
  getPlacePhoto(key, photoReference, maxHeight) {
    let url = "https://maps.googleapis.com/maps/api/place/photo";
    url += "?key=" + key;
    url += "&photoreference=" + photoReference;
    url += "&maxheight=" + maxHeight;
    return url;
  }
};
