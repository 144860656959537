<template>
  <div
    v-if="condo.place_id != nearbyCondo.place_id"
    class="card"
    :style="{
      backgroundImage: 'url(' + image + ')',
      height: this.cardHeight,
      width: this.cardWidth,
    }"
    @click="viewCondo(nearbyCondo.place_id)"
  >
    <header class="card-header">
      <p
        v-if="nearbyCondo.info"
        class="card-header-title is-size-6-7 pb-1"
        style="color: white;"
      >
        {{ nearbyCondo.info.name }}
      </p>
      <p v-else class="card-header-title is-size-6 pb-1" style="color: white;">
        {{ nearbyCondo.name }}
      </p>
    </header>
    <header class="card-header">
      <p
        v-if="!activeStation"
        class="card-header-title has-text-weight-medium is-size-7 pt-0"
        style="color: #e9eef2;"
      >
        {{ this.stationName }}
        <br />
        {{ this.stationDistance }}m to station
      </p>
      <p
        v-else
        class="card-header-title has-text-weight-medium is-size-7 pt-0"
        style="color: #e9eef2;"
      >
        {{ parseInt(nearbyCondo.station_distances[activeStation]) }}m to station
      </p>
    </header>

    <div class="overlay"></div>
  </div>
</template>
<script>
import { storage } from "@/firebase";
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import gmaps from "@/utils/gmaps";
import { db } from "@/firebase";

export default {
  name: "CondoCardImage",
  props: ["nearbyCondo", "cardHeight", "cardWidth"],
  data() {
    return {
      image: String,
      imageUrl: String,
      getPlacePhoto: gmaps.getPlacePhoto,
      stationName: "",
      stationDistance: "",
    };
  },
  created() {
    // this.getFirebaseImage()
    this.getCondoPhotos(this.nearbyCondo.place_id);
    if (this.nearbyCondo.highlight) {
      this.getNearestStationDetail(this.nearbyCondo.station_distances);
    }
  },
  watch: {
    nearbyCondo: function() {
      // console.log(this.condo.name);
      this.getCondoPhotos(this.nearbyCondo.place_id);
    },
    imageUrl: function() {
      if (this.imageUrl == "") {
        // this.imageUrl = this.getPlacePhoto(
        //   this.gmapsKey,
        //   this.nearbyCondo.photos[0].photo_reference,
        //   300
        // );
      } else {
        this.setImage();
      }
    },
  },
  methods: {
    ...mapActions(["fetchCondoPhotos"]),
    viewCondo(id) {
      router.push({
        name: "Condo",
        params: { id },
      });
    },
    getCondoPhotos(condoId) {
      let _this = this;
      db.ref("condo_photos")
        .child(condoId)
        .once("value", (snapshot) => {
          var docs = [];
          snapshot.forEach((doc) => {
            docs.push(doc.val());
          });
          if (docs.length > 0) {
            for (var i = docs.length - 1; i > 0; i--) {
              var j = Math.floor(Math.random() * (i + 1));
              var temp = docs[i];
              docs[i] = docs[j];
              docs[j] = temp;
            }
            _this.imageUrl = docs[0].photo_url;
          } else {
            _this.imageUrl = "";
          }
        });
    },
    getFirebaseImage() {
      let _this = this;
      storage
        .ref()
        .child("condos/" + this.nearbyCondo.place_id + "/gallery")
        .list({ maxResults: 1 })
        .then(function(res) {
          res.items.forEach(function(itemRef) {
            itemRef
              .getDownloadURL()
              .then(function(url) {
                _this.imageUrl = url;
                // this.$refs.condoImage.src = url;
              })
              .catch(function() {
                // console.log(error);
              });
          });
        });
    },
    setImage() {
      var that;
      var highResImage = new Image();
      that = this;
      highResImage.onload = function() {
        that.image = that.imageUrl;
      };
      highResImage.src = this.imageUrl;
    },
    getNearestStationDetail(distances) {
      let stationId = Object.keys(distances).reduce((a, b) =>
        distances[a] < distances[b] ? a : b
      );
      this.stationName = this.stations[stationId].name_en;
      this.stationDistance = parseInt(distances[stationId]);
    },
  },

  computed: {
    ...mapGetters([
      "condo",
      "gmapsKey",
      "activeStation",
      "condoPhotos",
      "stations",
    ]),
    console: () => console,
  },
};
</script>
<style></style>
